<template>
  <section>
    <div class="text-center">
      <strong class="font-size-24"> {{ this.nomeCliente }}</strong>
    </div>
    <b-overlay
      :show="loadingSpinner"
      rounded="sm"
      opacity="0.6"
      spinner-small
      spinner-variant="secondary"
    >
      <div class="p-1">
        <table class="table table-hover mx-auto">
          <thead class="text-center">
            <tr>
              <th scope="col">Guia</th>
              <th scope="col">Saldo Residual</th>
              <!-- <th scope="col">Periodo com saldo</th>
            </tr> -->
            </tr>
          </thead>

          <tbody class="text-center">
            <tr
              v-for="(guia, index) in listaGuias"
              :key="index"
              @click="rowClick(guia)"
            >
              <td scope="row">{{ formatDatePTBR(guia.guia) }}</td>
              <td scope="row">{{ formatMoney(guia.saldo) }}</td>
              <!-- <td scope="row">{{ guia.periodoApuracao }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import compensacaoService from "@/services/teses/compensacao.service";
import helperService from "../../../services/helper.service";

export default {
  name: "listaGuias",
  components: {},

  data() {
    return {
      loadingSpinner: false,
      listaGuias: [],
      nCodOp: "",
      cnpj: "",
      nomeCliente: "",
    };
  },

  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
    this.cnpj = this.$route.params.cnpj;
    this.getListaGuias();
  },

  methods: {
    async getListaGuias() {
      this.loadingSpinner = true;
      await compensacaoService
        .getListaGuias(this.nCodOp, this.cnpj)
        .then((response) => {
          console.log("response==>", response);
          if (response.status == 200) {
            this.listaGuias = response.data.dados;
            this.nomeCliente = this.listaGuias[0].nomeCliente;
          }
        })
        .catch((error) => {
          this.$toast.open({ message: error, type: "error", duration: 2000 });
          this.loadingSpinner = false;
        });

      this.loadingSpinner = false;
    },

    rowClick(file) {
      console.log("file", file);
      const data = file.guia;
      this.$router.push(
        `/calculo/compensacao/${this.nCodOp}/${this.cnpj}/${data}`
      );
    },

    formatMoney(number) {
      return helperService.formatMoneyPtBR(number);
    },

    formatDatePTBR(date) {
      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },
  },
};
</script>

<style scoped></style>
